import { useContext } from 'react'
import { TransitionContext } from 'components/Transition/Transition'
import { useRouter } from 'next/router'

export default function usePageTransition() {
  const { url, isPageLoaded } = useContext(TransitionContext)
  const router = useRouter()
  const isIn = url === router.asPath

  return { pageState: isIn && isPageLoaded ? 'in' : 'out', isPageLoaded }
}

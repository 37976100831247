import React, { memo, useState, useEffect, useImperativeHandle } from 'react'
import classnames from 'classnames'
import Ticker from 'react-ticker'
import styles from './Ticker.module.scss'
import useStore from 'store'

const AutoScroller = React.forwardRef(({ className, children, speed }, ref) => {
  const [isRunning, setIsRunning] = useState(true)
  const [showTicker, setShowTicker] = useState(true)
  const windowInFocusKey = useStore(state => state.windowInFocusKey)

  const reset = () => {
    setShowTicker(false)
    setTimeout(() => {
      setShowTicker(true)
    }, 5)
  }

  useImperativeHandle(ref, () => ({
    setIsRunning,
    reset,
  }))

  useEffect(() => {
    reset()
  }, [windowInFocusKey])

  return (
    <div className={classnames(styles.Ticker, className)}>
      {showTicker && (
        <Ticker
          speed={speed}
          move={isRunning}
        >
          {() => <div>{children}</div>}
        </Ticker>
      )}
    </div>
  )
})

AutoScroller.displayName = 'AutoScroller'

export default memo(AutoScroller)

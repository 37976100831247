import AboutModal from 'components/_modules/HomeSettings/AboutModal/AboutModal'
import Footer from 'components/_modules/HomeSettings/Footer/Footer'
import PillButton from 'components/PillButton/PillButton'
import PortfolioItemScroller from 'components/_modules/HomeSettings/PortfolioItemScroller/PortfolioItemScroller'
import styles from './HomeSettings.module.scss'
import usePageTransition from 'hooks/use-page-transition'
import { useEffect, useRef, useState } from 'react'
import gsap from 'gsap'
import { TRANSITION_TIME } from 'components/Transition/Transition'

const HomeSettings = ({ aboutModal, contact, logoSettings, footer, portfolioItems }) => {
  const { pageState } = usePageTransition()
  const contactButtonRef = useRef()
  const aboutButtonRef = useRef()
  const [aboutModalIsOpen, setAboutModalIsOpen] = useState(false)

  useEffect(() => {
    if (!contactButtonRef.current || !aboutButtonRef.current) return

    if (pageState === 'in') {
      gsap.fromTo(
        [contactButtonRef.current, aboutButtonRef.current],
        {
          y: -50,
          autoAlpha: 0,
        },
        {
          y: 0,
          autoAlpha: 1,
          duration: TRANSITION_TIME * 0.5,
          delay: TRANSITION_TIME,
        },
      )
    } else {
      gsap.to([contactButtonRef.current, aboutButtonRef.current], {
        autoAlpha: 0,
        y: -50,
        duration: TRANSITION_TIME * 0.5,
      })
    }
  }, [pageState])

  if (!aboutModal || !contact || !logoSettings || !portfolioItems.length) return null

  return (
    <div className={styles.HomeSettings}>
      <AboutModal
        {...aboutModal}
        aboutModalIsOpen={aboutModalIsOpen}
        setAboutModalIsOpen={setAboutModalIsOpen}
      />
      <Footer {...footer} />
      {aboutModal?.buttonText && (
        <PillButton
          ariaLabel="Open About Modal"
          label={aboutModal?.buttonText}
          className={styles.aboutButton}
          theme="red"
          onClick={() => {
            setAboutModalIsOpen(prev => !prev)
          }}
          elementRef={aboutButtonRef}
        />
      )}
      <a
        href={`mailto:${contact.email}`}
        className={styles.contactButton}
        ref={contactButtonRef}
      >
        <PillButton
          theme="purple"
          element="span"
          label="Contact"
        />
      </a>
      <PortfolioItemScroller
        logoSettings={logoSettings}
        portfolioItems={portfolioItems}
      />
    </div>
  )
}

HomeSettings.displayName = 'HomeSettings'

export default HomeSettings

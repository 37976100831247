import classnames from 'classnames'
import { PortableText } from '@portabletext/react'

import styles from './RichText.module.scss'

import Link from '../Link/Link'

const portableTextSerializer = {
  marks: {
    link: ({ value }) => {
      return <Link link={value}></Link>
    },
    highlighted: ({ children }) => {
      return <span className={styles.highlighted}>{children}</span>
    },
    displayText: ({ children }) => {
      return <span className={styles.displayText}>{children}</span>
    },
  },
}

function RichText({ className, content }) {
  return (
    <div
      className={classnames(styles.RichText, className)}
      data-component="RichText"
    >
      <PortableText
        value={content}
        components={portableTextSerializer}
      />
    </div>
  )
}

export default RichText

import React from 'react'
import NextLink from 'next/link'
import classnames from 'classnames'
import useStore from 'store'

import { event } from '../../utils/analytics'

const Link = React.forwardRef(({ className, children, link, onMouseEnter, onMouseLeave, linkOnly }, ref) => {
  const { linkType, label, link: url } = link
  const setCursorType = useStore(state => state.setCursorType)

  if (linkType === 'Internal' && !url?.slug) return null
  if (linkType === 'External' && !url) return null

  const handleClick = () => {
    event({
      action: 'clicked_link',
      params: {
        link: linkType === 'External' ? link : `/${url.slug}`,
      },
    })
  }

  if (linkType === 'External') {
    return (
      <a
        ref={ref}
        onClick={handleClick}
        href={url}
        className={classnames(className)}
        target="_blank"
        rel="noreferrer"
        onMouseEnter={() => {
          if (onMouseEnter) onMouseEnter()
          setCursorType('hover')
        }}
        onMouseLeave={() => {
          if (onMouseLeave) onMouseLeave()
          setCursorType('static')
        }}
      >
        {label && !children && !linkOnly && label}
        {children && children}
      </a>
    )
  } else if (linkType === 'Internal') {
    let slug = url.slug

    if (slug === 'home') {
      slug = ''
    }

    return (
      <NextLink
        ref={ref}
        onClick={handleClick}
        className={classnames(className)}
        href={`/${slug}`}
        scroll={false}
        onMouseEnter={() => {
          if (onMouseEnter) onMouseEnter()
        }}
        onMouseLeave={() => {
          if (onMouseLeave) onMouseLeave()
        }}
      >
        {label && !children && label}
        {children && children}
      </NextLink>
    )
  }
})

Link.displayName = 'Link'

export default Link

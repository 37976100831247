import { useEffect, useRef, useState } from 'react'
import classnames from 'classnames'
import styles from './PortfolioItemScroller.module.scss'
import PortfolioItemThumbnail from 'components/_modules/HomeSettings/PortfolioItemThumbnail/PortfolioItemThumbnail'
import useStore from 'store'
import LogoContainer from 'components/_modules/HomeSettings/LogoContainer/LogoContainer'
import usePageTransition from 'hooks/use-page-transition'
import gsap from 'gsap'
import { TRANSITION_TIME } from 'components/Transition/Transition'

const PortfolioItemScroller = ({ className, portfolioItems: items, logoSettings }) => {
  // const portfolioItems = [...items, ...items, ...items, ...items, ...items, ...items, ...items]
  const portfolioItems = [...items]
  const scrollerRef = useRef()
  const scrollerContentRef = useRef()
  const setPortfolioItemHoverIndex = useStore(state => state.setPortfolioItemHoverIndex)
  const setPortfolioItemClicked = useStore(state => state.setPortfolioItemClicked)
  const [animateInComplete, setAnimateInComplete] = useState(false)
  const { pageState } = usePageTransition()

  const animateInit = () => {
    if (!scrollerRef.current) return

    gsap.set(scrollerRef.current, {
      autoAlpha: 0,
    })
  }

  const animateIn = () => {
    if (!scrollerRef.current) return

    gsap.to(scrollerRef.current, {
      autoAlpha: 1,
      duration: TRANSITION_TIME,
      delay: 0.3,
    })
  }

  useEffect(() => {
    return () => {
      setPortfolioItemHoverIndex(false)
      setPortfolioItemClicked(false)
    }
  }, [setPortfolioItemHoverIndex, setPortfolioItemClicked])

  useEffect(() => {
    if (pageState === 'in') {
      animateInit()
      animateIn()
    }
  }, [pageState])

  if (!portfolioItems.length) return null

  return (
    <section
      className={classnames(styles.PortfolioItemScroller, className, {
        [styles.animateInComplete]: animateInComplete,
      })}
      style={{ '--number-of-items': portfolioItems.length }}
    >
      <div
        ref={scrollerRef}
        className={styles.scrollerOverflowContainer}
      >
        <div
          className={styles.scrollerContentContainer}
          ref={scrollerContentRef}
        >
          <div className={styles.scrollList}>
            {portfolioItems.map((item, i) => {
              return (
                <PortfolioItemThumbnail
                  animateInComplete={animateInComplete}
                  setAnimateInComplete={setAnimateInComplete}
                  className={styles.scrollList__item}
                  key={i}
                  index={i}
                  slug={item.slug}
                  title={item.title}
                  hasExplicitContent={item.hasExplicitContent}
                  thumbnailOptions={item.thumbnailOptions}
                  videoUrl={
                    item?.thumbnailOptions?.videoLoop?.asset?.size < 1250000
                      ? item?.thumbnailOptions?.videoLoop?.asset?.url
                      : null
                  }
                  type={item?.type}
                  images={item?.images}
                  videoFileUrl={item?.videoFileUrl}
                  videoLength={item?.videoLength}
                />
              )
            })}
            <LogoContainer {...logoSettings} />
          </div>
        </div>
      </div>
    </section>
  )
}

PortfolioItemScroller.displayName = 'PortfolioItemScroller'

export default PortfolioItemScroller

import classnames from 'classnames'
import styles from './CursorHover.module.scss'
import useStore from 'store'

const CursorHover = ({ className, children, cursorType, element = 'div' }) => {
  const setCursorType = useStore(state => state.setCursorType)
  const Element = element

  if (!cursorType) return null

  return (
    <Element
      onMouseEnter={() => {
        setCursorType(cursorType)
      }}
      onMouseLeave={() => {
        setCursorType(null)
      }}
      className={classnames(styles.CursorHover, className)}
    >
      {children}
    </Element>
  )
}

CursorHover.displayName = 'CursorHover'

export default CursorHover

import { useEffect, useRef } from 'react'
import classnames from 'classnames'
import styles from './Footer.module.scss'
import Ticker from 'components/Ticker/Ticker'
import useStore from 'store'
import usePageTransition from 'hooks/use-page-transition'
import gsap from 'gsap'
import { TRANSITION_TIME } from 'components/Transition/Transition'

const Footer = ({ className, brandName, copyright, longText }) => {
  const portfolioItemHoverIndex = useStore(state => state.portfolioItemHoverIndex)
  const { pageState } = usePageTransition()
  const containerRef = useRef()

  useEffect(() => {
    if (!containerRef.current) return

    gsap.killTweensOf(containerRef.current)

    if (pageState === 'in') {
      gsap.fromTo(
        containerRef.current,
        {
          autoAlpha: 0,
        },
        {
          autoAlpha: 1,
          duration: 0.5,
          delay: TRANSITION_TIME,
        },
      )
    } else {
      gsap.to(containerRef.current, {
        autoAlpha: 0,
        duration: TRANSITION_TIME,
      })
    }
  }, [pageState])

  if (!brandName || !copyright || !longText) return null

  return (
    <footer
      ref={containerRef}
      className={classnames(styles.Footer, className, { [styles.isHovering]: portfolioItemHoverIndex !== false })}
    >
      <div className={styles.brand}>{brandName}</div>
      {longText && (
        <Ticker>
          <div className={styles.longText}>{longText}</div>
        </Ticker>
      )}

      <div className={styles.copyright}>{copyright}</div>
    </footer>
  )
}

Footer.displayName = 'Footer'

export default Footer

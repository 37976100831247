import { useCallback, useEffect, useRef, useState } from 'react'
import classnames from 'classnames'
import styles from './PortfolioItemThumbnail.module.scss'
import SanityImage from 'components/SanityImage/SanityImage'
import Ticker from 'components/Ticker/Ticker'
import useNewKeyOnWindowResize from 'hooks/use-new-key-on-window-resize'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
import { patternInterval } from 'styles/export-vars.module.scss'
import useStore from 'store'
import ExplicitImage from 'assets/images/explicit.png'
import { SCROLL_CONTAINER_CLASS } from 'components/Layout/Layout'
import usePageTransition from 'hooks/use-page-transition'
import { TRANSITION_TIME, TRANSITION_TIME_MS } from 'components/Transition/Transition'
import { useRouter } from 'next/router'
import useBreakpoint from 'hooks/use-breakpoint'

const PATTERN_INTERVAL = parseInt(patternInterval)
const PARALLAX_SPEEDS = [1.4, 1, 0.8, 1, 1, 1.3, 1, 1.3, 1]
const PARALLAX_SPEEDS_MOBILE = [1.2, 1, 0.8, 1, 1, 1.1, 1, 0.8, 1]
const ANIMATE_UP_UNTIL_INDEX = 4

export const getPatternIndexFromIndex = index => index % PATTERN_INTERVAL

ScrollTrigger.config({
  ignoreMobileResize: true,
})

gsap.registerPlugin(ScrollTrigger)

const PortfolioItemThumbnail = ({
  className,
  slug,
  title,
  hasExplicitContent,
  thumbnailOptions,
  index,
  setAnimateInComplete,
  animateInComplete,
  videoUrl,
  type,
  images,
  videoLength,
}) => {
  const router = useRouter()
  const [hasTicker, setHasTicker] = useState(false)
  const [isInView, setIsInView] = useState(false)
  const [isHovering, setIsHovering] = useState(false)
  const [scrollTriggerIsSetup, setScrollTriggerIsSetup] = useState(false)
  const metaDataTitleContainerRef = useRef()
  const metaDataBgRef = useRef()
  const titleContainerRef = useRef()
  const titleRef = useRef()
  const metaDataRef = useRef()
  const containerRef = useRef()
  const imageContainerInnerRef = useRef()
  const imageScaleRef = useRef()
  const innerTransformContainerRef = useRef()
  const scrollTriggerRef = useRef()
  const imageContainerRef = useRef()
  const timelineRef = useRef()
  const contentRef = useRef()
  const tickerRef = useRef()
  const videoRef = useRef()
  const explicitBadgeRef = useRef()
  const explicitBadgeTimelineRef = useRef()
  const setPortfolioItemHoverIndex = useStore(state => state.setPortfolioItemHoverIndex)
  const portfolioItemHoverIndex = useStore(state => state.portfolioItemHoverIndex)
  const setPortfolioItemClicked = useStore(state => state.setPortfolioItemClicked)
  const portfolioItemClicked = useStore(state => state.portfolioItemClicked)
  const setCenteredPortfolioItemIndex = useStore(state => state.setCenteredPortfolioItemIndex)
  const centeredPortfolioItemIndex = useStore(state => state.centeredPortfolioItemIndex)
  const patternIndex = getPatternIndexFromIndex(index)
  const setCursorType = useStore(state => state.setCursorType)
  const breakpoint = useStore(state => state.breakpoint)
  const { pageState } = usePageTransition()
  const prevPath = useStore(state => state.prevPath)
  const { isMobile } = useBreakpoint()
  const videoInViewTimeoutRef = useRef()
  const [showVideo, setShowVideo] = useState(false)
  const showVideoRef = useRef(false)
  const [videoIsLoaded, setVideoIsLoaded] = useState(false)
  const key = useNewKeyOnWindowResize()
  const observerRef = useRef()

  const handleClick = () => {
    if (portfolioItemClicked) return

    setTimeout(
      () => {
        router.push(`/work/${slug.current}`)
      },
      isMobile ? TRANSITION_TIME_MS * 0.5 : 0,
    )

    setPortfolioItemClicked(true)
    setPortfolioItemHoverIndex(patternIndex)
  }

  // On click
  useEffect(() => {
    if (!portfolioItemClicked || !containerRef.current) return

    const isSelected = patternIndex === portfolioItemHoverIndex

    gsap.to(containerRef.current, {
      autoAlpha: 0,
      delay: isSelected ? TRANSITION_TIME * 0.5 : 0,
      duration: TRANSITION_TIME,
    })
  }, [portfolioItemClicked, patternIndex, portfolioItemHoverIndex, router])

  const animateCardsInInitial = useCallback(() => {
    const isValid = index < ANIMATE_UP_UNTIL_INDEX

    if (prevPath) return

    if (!isValid) {
      gsap.set(innerTransformContainerRef.current, {
        autoAlpha: 1,
      })
    } else {
      gsap.set(innerTransformContainerRef.current, {
        y: '100vh',
        x: `${gsap.utils.random(-20, 20)}%`,
        scale: 1.2,
        rotate: gsap.utils.random(-80, 80),
      })
    }
  }, [index, prevPath])

  const animateCardsIn = useCallback(async () => {
    if (!innerTransformContainerRef.current) return

    const isValid = index < ANIMATE_UP_UNTIL_INDEX
    const delay = index * 0.09 + 0.5

    if (prevPath) {
      setAnimateInComplete(true)
      return gsap.to(innerTransformContainerRef.current, {
        duration: TRANSITION_TIME,
        delay: isValid ? delay : 0,
      })
    }

    if (!isValid) return

    gsap.to(innerTransformContainerRef.current, {
      x: 0,
      y: 0,
      scale: 1,
      rotate: '0deg',
      ease: 'Power4.easeOut',
      duration: 1.8,
      delay,
      onComplete: () => {
        if (index === ANIMATE_UP_UNTIL_INDEX - 2) {
          setAnimateInComplete(true)
        }
      },
    })
  }, [index, setAnimateInComplete, prevPath])

  useEffect(() => {
    if (!containerRef.current || !imageContainerInnerRef) return

    if (scrollTriggerRef.current) {
      scrollTriggerRef.current.kill()
    }

    if (timelineRef.current) {
      timelineRef.current.kill()
    }

    timelineRef.current = gsap.timeline()

    const distance = breakpoint.name === 'mobile' ? 20 : 30
    const multiplier = breakpoint.name === 'mobile' ? PARALLAX_SPEEDS_MOBILE : PARALLAX_SPEEDS
    const y = `-${distance * multiplier[patternIndex]}vh`

    timelineRef.current.fromTo(
      containerRef.current,
      {
        rotate: `${gsap.utils.random(-10, 10)}deg`,
      },
      {
        y,
        rotate: `${gsap.utils.random(-2, 2, 0.5)}deg`,
        ease: 'linear',
      },
      0,
    )

    timelineRef.current.to(
      imageContainerInnerRef.current,
      {
        y: '20%',
        ease: 'linear',
      },
      0,
    )

    scrollTriggerRef.current = new ScrollTrigger({
      trigger: containerRef.current,
      start: 'top bottom',
      end: 'top top',
      scrub: 2,
      animation: timelineRef.current,
      scroller: `.${SCROLL_CONTAINER_CLASS}`,
      onToggle: self => {
        setIsInView(self.isActive)
      },
      onRefresh: () => {
        setScrollTriggerIsSetup(true)
      },
    })
    return () => {
      if (scrollTriggerRef.current) {
        scrollTriggerRef.current.kill()
      }

      if (timelineRef.current) {
        timelineRef.current.kill()
      }
    }
  }, [key, patternIndex, breakpoint])

  //Observe when in middle of viewport
  useEffect(() => {
    if (!containerRef.current || !isMobile) return

    if (observerRef.current) {
      observerRef.current.disconnect()
    }

    observerRef.current = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            setCenteredPortfolioItemIndex(index)
          }
        })
      },
      {
        rootMargin: '-50% 0% -50% 0%',
        threshold: 0,
      },
    )

    observerRef.current.observe(containerRef.current)

    return () => {
      observerRef.current.disconnect()
    }
  }, [isMobile, setCenteredPortfolioItemIndex, index])

  // eslint-disable-next-line
  const animateExplicitBadge = animateIn => {
    if (!explicitBadgeRef.current) return

    if (explicitBadgeTimelineRef.current) {
      explicitBadgeTimelineRef.current.kill()
    }

    explicitBadgeTimelineRef.current = gsap.timeline({
      paused: true,
      onStart: () => {
        gsap.set(explicitBadgeRef.current, {
          autoAlpha: 1,
        })
      },
      onComplete: () => {
        if (animateIn) {
          gsap.set(explicitBadgeRef.current, {
            autoAlpha: 0,
          })
        }
      },
    })

    const scales = []

    for (let index = 0; index < 7; index++) {
      scales.push(gsap.utils.random(0.2, 1.8, 0.1))
    }

    scales.forEach((scale, i) => {
      const _5 = gsap.utils.random(0, 5)
      const _100 = gsap.utils.random(90, 100)
      const _75 = gsap.utils.random(60, 85)
      const _50 = gsap.utils.random(40, 65)

      const isLastItem = i === scales.length - 1

      /* eslint-disable */
      explicitBadgeTimelineRef.current.set(
        explicitBadgeRef.current,
        {
          scale: isLastItem ? 1 : scale,
          rotate: isLastItem ? '0deg' : `${gsap.utils.random(-20, 20)}deg`,
          scaleX: isLastItem ? 0 : gsap.utils.random(0.9, 1.2),
          scaleY: isLastItem ? 0 : gsap.utils.random(0.9, 1.2),
          clipPath: isLastItem
            ? 'none'
            : `polygon(${_5}% ${gsap.utils.random(
                5,
                50,
              )}%, ${_100}% 0%, ${_75}% ${_75}%, ${_75}% ${_50}%, ${_50}% ${_100}%, ${_50}% ${_50}%, 0% ${_100}%)`,
        },
        i * 0.05,
      )
      /* eslint-enable */
    })

    if (animateIn) {
      explicitBadgeTimelineRef.current.play()
    } else {
      explicitBadgeTimelineRef.current.reverse()
      explicitBadgeTimelineRef.current.play()
    }
  }

  useEffect(() => {
    animateExplicitBadge(isHovering)
  }, [isHovering])

  useEffect(() => {
    if (!scrollTriggerIsSetup) return

    if (pageState === 'in') {
      animateCardsInInitial()
      animateCardsIn()
    }
  }, [pageState, scrollTriggerIsSetup, animateCardsIn, animateCardsInInitial, index])

  useEffect(() => {
    if (!titleContainerRef.current || !metaDataRef.current || !metaDataBgRef.current) return

    if (breakpoint.name === 'tablet' || breakpoint.name === 'mobile') {
      gsap.set([metaDataBgRef.current, titleContainerRef.current, metaDataRef.current], {
        clearProps: 'all',
      })

      return
    }

    if (isHovering && tickerRef.current) {
      tickerRef.current.reset()
    }

    gsap.killTweensOf([titleContainerRef.current, metaDataRef.current, metaDataBgRef.current])

    gsap.to([metaDataBgRef.current, titleContainerRef.current, metaDataRef.current], {
      y: isHovering ? '100%' : '0',
      duration: isHovering ? 0.6 : 0.3,
      stagger: isHovering ? 0.1 : 0,
      ease: 'Power3.easeOut',
      autoAlpha: isHovering ? 1 : 0,
      onComplete: () => {
        if (!isHovering && tickerRef.current) {
          tickerRef.current.setIsRunning(false)
        }
      },
    })

    if (!imageScaleRef.current) return

    gsap.killTweensOf(imageScaleRef.current)

    gsap.to(imageScaleRef.current, {
      scale: isHovering ? 1.1 : 1,
      ease: 'Power3.easeOut',
      duration: isHovering ? 2 : 0.6,
    })
  }, [isHovering, breakpoint, pageState])

  useEffect(() => {
    if (!innerTransformContainerRef.current || !animateInComplete) return

    gsap.killTweensOf(innerTransformContainerRef.current)

    const isActive = portfolioItemHoverIndex === patternIndex

    let autoAlpha = portfolioItemHoverIndex === false ? 1 : isActive ? 1 : 0.3
    if (pageState === 'out') {
      autoAlpha = 0
    }

    gsap.to(innerTransformContainerRef.current, {
      autoAlpha,
      ease: 'Power4.easeOut',
      duration: 1,
    })
  }, [patternIndex, portfolioItemHoverIndex, animateInComplete, pageState])

  useEffect(() => {
    setTimeout(() => {
      if (!metaDataTitleContainerRef.current || !titleRef.current || !metaDataRef.current) return

      const titleWidth = titleRef.current.offsetWidth
      const metaDataWidth = metaDataRef.current.offsetWidth
      const metaDataTitleContainerWidth = metaDataTitleContainerRef.current.offsetWidth

      const room = metaDataTitleContainerWidth - metaDataWidth
      const isLarger = titleWidth > room

      setHasTicker(isLarger)

      setTimeout(() => {
        if (tickerRef.current) {
          tickerRef.current.setIsRunning(true)
        }
      }, 50)
    }, 100)
  }, [key, setHasTicker, slug, index])

  useEffect(() => {
    const scrollContainer = document.querySelectorAll(`.${SCROLL_CONTAINER_CLASS}`)[0]
    if (!prevPath?.includes(slug.current) || !scrollContainer) return

    const offsetTop = containerRef.current.offsetTop
    const scrollTo = offsetTop - window.innerHeight * 0.5

    scrollContainer.scrollTo(0, scrollTo)
  }, [slug, prevPath])

  useEffect(() => {
    if (tickerRef.current && (breakpoint.name === 'tablet' || breakpoint.name === 'mobile')) {
      if (isInView) {
        tickerRef.current.setIsRunning(true)
        tickerRef.current.reset()
      } else {
        tickerRef.current.setIsRunning(false)
      }
    }
  }, [isInView, breakpoint.name])

  useEffect(() => {
    showVideoRef.current = showVideo
  }, [showVideo])

  useEffect(() => {
    if (!videoUrl || !videoRef.current) return

    if (videoInViewTimeoutRef.current) {
      clearTimeout(videoInViewTimeoutRef.current)
    }

    const handler = function () {
      setVideoIsLoaded(true)
    }

    videoRef.current.removeEventListener('canplaythrough', handler)

    // desktop vs mobile conditions
    let condition = isInView

    if (isMobile) {
      condition = isInView && centeredPortfolioItemIndex === index
    }

    if (condition) {
      if (!showVideoRef.current) {
        videoInViewTimeoutRef.current = setTimeout(
          () => {
            if (videoRef.current) {
              setShowVideo(true)

              setTimeout(() => {
                videoRef.current.src = videoUrl
                videoRef.current.currentTime = 0
                videoRef.current.addEventListener('canplaythrough', handler)
              }, 50)
            }
          },
          isMobile ? 200 : 1000,
        )
      }
    } else {
      videoRef.current.src = ''
      videoRef.current.removeEventListener('canplaythrough', handler)
      videoRef.current.pause()
      setShowVideo(false)
      setVideoIsLoaded(false)
      gsap.set(videoRef.current, {
        clearProps: 'all',
      })
    }
  }, [isInView, videoIsLoaded, centeredPortfolioItemIndex, index, isMobile, videoUrl])

  useEffect(() => {
    if (!videoRef.current || !videoIsLoaded) return
    videoRef.current.play()
    gsap.to(videoRef.current, {
      autoAlpha: 1,
      duration: 0.8,
    })
  }, [videoIsLoaded])

  if (!slug || !title || !thumbnailOptions || !thumbnailOptions?.image) return null

  return (
    <article
      className={classnames(
        styles.PortfolioItemThumbnail,
        className,
        {
          [styles[thumbnailOptions.imageSize]]: thumbnailOptions.imageSize,
        },
        { [styles.isHovered]: portfolioItemHoverIndex === patternIndex },
      )}
      data-type={type}
      ref={containerRef}
      onMouseEnter={() => {
        if (breakpoint === 'mobile') return

        setCursorType('hoverThumbnail')
        setPortfolioItemHoverIndex(patternIndex)
        setIsHovering(true)

        if (tickerRef.current) {
          tickerRef.current.setIsRunning(true)
        }
      }}
      onMouseLeave={() => {
        if (breakpoint === 'mobile') return

        setCursorType(null)
        setPortfolioItemHoverIndex(false)
        setIsHovering(false)
      }}
      style={{
        '--content-width': `${contentRef.current ? contentRef.current.offsetWidth : 0}px`,
        '--content-height': `${contentRef.current ? contentRef.current.offsetHeight : 0}px`,
        '--aspect-ratio': `${
          contentRef.current ? `${contentRef.current.offsetWidth} / ${contentRef.current.offsetHeight}` : '1 / 1'
        }`,
      }}
      onClick={handleClick}
    >
      <div
        className={styles.hoverTransformContainer}
        ref={innerTransformContainerRef}
      >
        <div className={styles.inner}>
          <div
            className={styles.content}
            ref={contentRef}
          >
            {hasExplicitContent && (
              <img
                src={ExplicitImage.src}
                width={100}
                height={100}
                alt="Explicit Content"
                className={styles.explicitBadge}
                ref={explicitBadgeRef}
              />
            )}
            <div
              className={styles.imageContainer}
              ref={imageContainerRef}
            >
              <div
                className={styles.imageContainer__inner}
                ref={imageContainerInnerRef}
              >
                <div
                  ref={imageScaleRef}
                  className={styles.imageContainer__scaleContainer}
                >
                  <SanityImage
                    image={thumbnailOptions.image}
                    breakpoints={[
                      {
                        breakpoint: '1440',
                        image: thumbnailOptions.image,
                        width: 1000,
                      },
                      {
                        breakpoint: '768',
                        image: thumbnailOptions.image,
                        width: 800,
                      },
                      {
                        breakpoint: '0',
                        image: thumbnailOptions.image,
                        width: 500,
                      },
                    ]}
                    unoptimized
                    className={styles.image}
                  />
                  {videoUrl && (
                    <video
                      muted
                      playsInline
                      loop
                      autoPlay
                      className={styles.video}
                      ref={videoRef}
                    />
                  )}
                </div>
              </div>
            </div>
            <div
              className={styles.metaDataTitleContainer}
              ref={metaDataTitleContainerRef}
            >
              <div
                className={styles.metaDataBg}
                ref={metaDataBgRef}
              />
              {hasTicker && isInView ? (
                <div
                  ref={titleContainerRef}
                  className={styles.titleContainer}
                >
                  <Ticker
                    className={styles.titleTicker}
                    ref={tickerRef}
                  >
                    <p className={styles.title}>{title}</p>
                  </Ticker>
                </div>
              ) : (
                <div
                  ref={titleContainerRef}
                  className={styles.titleContainer}
                >
                  <p
                    className={styles.title}
                    ref={titleRef}
                  >
                    {title}
                  </p>
                </div>
              )}

              <div
                ref={metaDataRef}
                className={styles.metaData}
              >
                {type === 'video' && videoLength && <>{videoLength}</>}
                {type === 'gallery' && images?.length > 0 && <>/ {images.length}</>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </article>
  )
}

PortfolioItemThumbnail.displayName = 'PortfolioItemThumbnail'

export default PortfolioItemThumbnail

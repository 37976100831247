import classnames from 'classnames'
import SanityImage from 'components/SanityImage/SanityImage'
import styles from './LogoContainer.module.scss'
import { useCallback, useEffect, useRef } from 'react'
import useStore from 'store'
import gsap from 'gsap'
import { TRANSITION_TIME } from 'components/Transition/Transition'
import usePageTransition from 'hooks/use-page-transition'
import useBreakpoint from 'hooks/use-breakpoint'

const LogoContainer = ({ className, desktopLogo, desktopLogoHover, isHover }) => {
  const { pageState } = usePageTransition()
  const portfolioItemHoverIndex = useStore(state => state.portfolioItemHoverIndex)
  const portfolioItemClicked = useStore(state => state.portfolioItemClicked)
  const containerRef = useRef()
  const { isMobile } = useBreakpoint()

  const animateInit = useCallback(() => {
    gsap.killTweensOf(containerRef.current)
    gsap.set(containerRef.current, {
      autoAlpha: 0,
      y: '-100%',
    })
  }, [])

  useEffect(() => {
    if (!containerRef.current) return

    if (pageState === 'in' && !portfolioItemClicked) {
      animateInit()
      gsap.to(containerRef.current, {
        autoAlpha: 1,
        y: 0,
        ease: 'Power3.easeOut',
        duration: TRANSITION_TIME,
      })
    } else {
      gsap.to(containerRef.current, {
        autoAlpha: 0,
        duration: TRANSITION_TIME * 0.5,
        delay: isMobile ? 0 : TRANSITION_TIME * 0.5,
      })
    }
  }, [pageState, animateInit, isMobile, portfolioItemClicked])

  if (!desktopLogo || !desktopLogoHover) return null

  return (
    <div
      className={classnames(styles.LogoContainer, className, {
        [styles.isHover]: isHover || portfolioItemHoverIndex !== false,
      })}
      ref={containerRef}
    >
      <SanityImage
        className={classnames(styles.logo, styles.desktop)}
        image={desktopLogo}
        breakpoints={[
          {
            breakpoint: 768,
            image: desktopLogo,
            width: 3400,
          },
        ]}
        unoptimized
      />
      <SanityImage
        className={classnames(styles.logo, styles.desktop, styles.hoverLogo)}
        image={desktopLogoHover}
        breakpoints={[
          {
            breakpoint: 768,
            image: desktopLogoHover,
            width: 3400,
          },
        ]}
        unoptimized
      />
      <SanityImage
        className={classnames(styles.logo, styles.mobile)}
        image={desktopLogo}
        breakpoints={[
          {
            breakpoint: 420,
            image: desktopLogo,
            width: 800,
          },
          {
            breakpoint: 1,
            image: desktopLogo,
            width: 500,
          },
        ]}
        unoptimized
      />
      <SanityImage
        className={classnames(styles.logo, styles.mobile, styles.hoverLogo)}
        image={desktopLogoHover}
        breakpoints={[
          {
            breakpoint: 420,
            image: desktopLogoHover,
            width: 800,
          },
          {
            breakpoint: 1,
            image: desktopLogoHover,
            width: 500,
          },
        ]}
        unoptimized
      />
    </div>
  )
}

LogoContainer.displayName = 'LogoContainer'

export default LogoContainer

import { useState, useRef } from 'react'
import classnames from 'classnames'
import { useEffect } from 'react'
import gsap from 'gsap'
import styles from './PillButton.module.scss'
import { STATES as CURSOR_STATES } from 'components/Cursor/Cursor'
import useStore from 'store'
import InfoIcon from 'assets/svgs/info.svg'
import useBreakpoint from 'hooks/use-breakpoint'

const PillButton = ({
  className,
  ariaLabel,
  label,
  onClick,
  onMouseEnter,
  onMouseLeave,
  theme,
  type,
  element = 'button',
  elementRef,
}) => {
  const [isHover, setIsHover] = useState(false)
  const textRefs = useRef([])
  const setCursorType = useStore(state => state.setCursorType)
  const Element = element
  const { isMobile } = useBreakpoint()

  useEffect(() => {
    if (!textRefs.current.length || isMobile) return

    setCursorType(isHover ? CURSOR_STATES.hover : CURSOR_STATES.static)

    gsap.killTweensOf(textRefs.current)

    gsap.to(textRefs.current, {
      y: i => {
        const y = i === 0 ? '-110%' : '-100%'
        return isHover ? y : '0'
      },
      duration: 0.3,
      ease: 'Power3.easeOut',
    })
  }, [isHover, setCursorType, isMobile])

  if (!label) return null

  return (
    <Element
      onMouseEnter={() => {
        setIsHover(true)
        if (onMouseEnter) onMouseEnter()
      }}
      onMouseLeave={() => {
        setIsHover(false)
        if (onMouseLeave) onMouseLeave()
      }}
      aria-label={ariaLabel ? ariaLabel : undefined}
      className={classnames(
        styles.PillButton,
        className,
        { [styles[theme]]: theme },
        { [styles.infoButton]: type === 'infoButton' },
      )}
      onClick={() => {
        if (onClick) onClick()
      }}
      ref={elementRef}
    >
      <span
        ref={ref => {
          textRefs.current[0] = ref
        }}
        className={styles.text}
      >
        {label}
        {type === 'infoButton' && <InfoIcon className={styles.infoIcon} />}
      </span>
      <span
        ref={ref => {
          textRefs.current[1] = ref
        }}
        className={styles.textHover}
      >
        {label}
        {type === 'infoButton' && <InfoIcon className={styles.infoIcon} />}
      </span>
    </Element>
  )
}

PillButton.displayName = 'PillButton'

export default PillButton

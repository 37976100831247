import { useEffect, useRef } from 'react'
import classnames from 'classnames'
import RichText from 'components/RichText/RichText'
import SanityImage from 'components/SanityImage/SanityImage'
import styles from './AboutModal.module.scss'
import gsap from 'gsap'
import CursorHover from 'components/CursorHover/CursorHover'
import usePageTransition from 'hooks/use-page-transition'
import { TRANSITION_TIME } from 'components/Transition/Transition'
import Link from 'components/Link/Link'

const AboutModal = ({
  className,
  buttonText,
  richTextContent,
  image,
  people,
  contactMethods,
  aboutModalIsOpen,
  setAboutModalIsOpen,
}) => {
  const containerRef = useRef()
  const buttonRef = useRef()
  const bgRef = useRef()
  const { pageState } = usePageTransition()

  useEffect(() => {
    if (!buttonRef.current) return

    gsap.killTweensOf(buttonRef.current)

    if (pageState === 'in') {
      gsap.fromTo(
        buttonRef.current,
        {
          y: -50,
          autoAlpha: 0,
        },
        {
          y: 0,
          autoAlpha: 1,
          duration: TRANSITION_TIME * 0.5,
          delay: TRANSITION_TIME,
        },
      )
    } else {
      gsap.to(buttonRef.current, {
        y: -50,
        autoAlpha: 0,
        duration: TRANSITION_TIME * 0.5,
      })
    }
  }, [pageState])

  useEffect(() => {
    if (!containerRef.current || !bgRef.current) return

    gsap.killTweensOf(containerRef.current)

    gsap.to(containerRef.current, {
      x: aboutModalIsOpen ? '0%' : '-130%',
      duration: aboutModalIsOpen ? 0.8 : 0.4,
      rotate: aboutModalIsOpen ? '0deg' : '-10deg',
      ease: 'Power3.easeOut',
    })

    gsap.set(bgRef.current, {
      display: aboutModalIsOpen ? 'block' : 'none',
    })
  }, [aboutModalIsOpen])

  if (!buttonText || !richTextContent || !image || !contactMethods.length || !people) return null

  return (
    <>
      <div
        className={styles.aboutModalBg}
        ref={bgRef}
        onClick={() => {
          setAboutModalIsOpen(false)
        }}
      />
      <section
        ref={containerRef}
        className={classnames(styles.AboutModal, className)}
      >
        <div className={styles.inner}>
          <button
            className={styles.close}
            aria-label="Close About Modal"
            onClick={() => {
              setAboutModalIsOpen(false)
            }}
          >
            <CursorHover
              className={styles.closeInner}
              element="span"
              cursorType="hover"
            >
              [x]
            </CursorHover>
          </button>
          <div
            className={styles.innerScroll}
            data-lenis-prevent
          >
            <div className={styles.rta}>
              <RichText content={richTextContent} />
            </div>
            {people?.title && people?.names?.length > 0 && (
              <div className={styles.peopleContainer}>
                <h2 className={styles.peopleTitle}>
                  <span>{people.title}</span>
                  <span>[{people.names.length}]</span>
                </h2>
                <ul className={styles.peopleList}>
                  {people.names.map((person, i) => {
                    return (
                      <li
                        className={styles.peopleList__item}
                        key={i}
                      >
                        {person?.websiteLink ? (
                          <a
                            href={person.websiteLink}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <CursorHover
                              element="span"
                              cursorType="hover"
                            >
                              {person?.name}
                            </CursorHover>
                          </a>
                        ) : (
                          <span>{person?.name}</span>
                        )}
                      </li>
                    )
                  })}
                </ul>
                <div className={styles.imageContainer}>
                  <SanityImage
                    unoptimized
                    image={image}
                    className={styles.image}
                  />
                </div>
              </div>
            )}
          </div>
          <ul className={styles.contactMethods}>
            {contactMethods.map((method, i) => {
              if (!method.type || !method.link) return null

              return (
                <li
                  className={styles.contactMethods__method}
                  key={i}
                >
                  <div className={styles.contactMethods__type}>{method.type}</div>
                  <div className={styles.contactMethods__value}>
                    <Link link={method.link} />
                  </div>
                </li>
              )
            })}
          </ul>
        </div>
      </section>
    </>
  )
}

AboutModal.displayName = 'AboutModal'

export default AboutModal
